<template>
  <div class="merchants main-wrap">
    <!-- 搜索 -->
    <search-form
      :show-exam="false"
      is-merchant="true"
      @search="search"
    ></search-form>
    <!-- 商户列表 -->
    <merchants-table
      :list="list"
      @edit="handleEdit"
      @del="handleDel"
      @view="handleView"
      @changeXjd="onChangeXjd"
      @changeShare="onChangeShare"
      @sortChange="handleSort"
    ></merchants-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="currentPage"
    ></el-pagination>
    <edit-form
      :is-show="showEdit"
      :edit-data="editData"
      @cancel="showEdit = false"
      @submit="submitEdit"
    ></edit-form>
  </div>
</template>

<script>
// components
import searchForm from "../components/search-form";
import merchantsTable from "./components/merchants-table";
// import viewBar from '../components/view-bar';
import editForm from "./components/edit-form";

import service from "./api";
import pagination from "@/mixins/pagination";
// import manage from '../manage';
export default {
  mixins: [pagination],
  data() {
    return {
      list: [],
      page: {},
      showEdit: false,
      editData: {},
      viewData: null,
      searchData: {},
      //当前页
      currentPage: 1
    };
  },
  components: {
    searchForm,
    merchantsTable,
    editForm
  },
  methods: {
    // 初始化
    init(currentPage) {
      let initParams = {
        ...this.searchData,
        pageNum: currentPage,
        pageSize: 10
      };
      return this.getList(initParams);
    },
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service.getList(params).then(res => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
          // item.is_xjd = item.roleId === 2;
          item.is_share = item.is_promote === 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    // 搜索
    search(data) {
      let params = Object.assign(data, { pageNum: 1 });
      this.searchData = data;
      return this.getList(params);
    },
    handleEdit(row) {
      this.editData = row;
      this.showEdit = true;
    },
    // 提交审核
    submitEdit(form) {
      return service
        .check({ merchantId: this.editData.userId, status: form.status })
        .then(res => {
          service.recharge({merchantId: this.editData.userId, amount: Math.floor(form.amount * 100), promote: Math.floor(form.promote * 100)}).then(res => {
            this.$message({ message: "操作成功!", type: "success" });
            this.showEdit = false;
            this.init(this.currentPage);
          })
        });
    },
    handleView(row) {},
    onChangeXjd(row) {
      return service
        .switchXjd({
          merchantId: row.userId,
          roleId: row.roleId
        })
        .then(res => {
          this.$message({ message: "操作成功!", type: "success" });
          this.init(this.currentPage);
        });
    },
    onChangeShare(row) {
      return service
        .switchShare({
          merchantId: row.userId,
          isPromote: row.isPromote
        })
        .then(res => {
          this.$message({ message: "操作成功!", type: "success" });
          this.init(this.currentPage);
        });
    },
    handleDel(row){
      this.$alert(
        `是否确认将商户"${row.realName || row.mobile}"删除?`,
        "编辑",
        { showCancelButton: true }
      ).then(() => {
        return service
          .delMerchants({
            userId: row.userId
          })
          .then(res => {
            this.$message({ message: "操作成功!", type: "success" });
            this.init(this.currentPage);
          }).catch();
      })
    }
  },
  mounted() {
    this.init(this.currentPage);
  }
};
</script>

<style lang="scss" scoped></style>
