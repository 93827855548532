<template>
  <div class="dialog">
    <el-dialog
      title="编辑"
      :visible.sync="isShow"
      width="40%"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form ref="form" :model="form" label-width="80px" class="form" hide-required-asterisk>
        <el-form-item label="状态" prop="status">
          <el-select v-model="form.status" placeholder="选择审核状态">
            <el-option
              v-for="item in statusOpts"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="风控充值" prop="amount">
          <el-input placeholder="请输入充值金额" v-model="form.amount" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="星币充值" prop="promote">
          <el-input placeholder="请输入充值金额" v-model="form.promote" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    editData: {
      type: Object,
      default: () => {}
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { status: '' , amount: '', promote: ''},
      statusOpts: [
        { id: 0, label: '通过' },
        { id: 1, label: '拒绝' },
        { id: 2, label: '待审核' }
      ]
    };
  },
  methods: {
    openDialog() {
      // 填充编辑表单数据
      this.$nextTick(() => {
        this.form.status = this.editData.status;
        this.form.amount = '';
        this.form.promote = '';
      });
    },
    submitForm(){
      this.$emit('submit', this.form);
    },
    cancelForm(){
      this.$emit('cancel');
      this.$refs['form'].resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog {
  .form {
    width: 80%;
    margin: 0 auto;
    text-align: left;
    .el-select {
      width: 100%;
    }
  }
}
</style>
