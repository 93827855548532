import API from "@/api/index";

export default {
  getList: param => {
    return API.requestGet({
      params: {
        type: 2,
        ...param
      },
      url: "user/getList"
    });
  },
  switch: param => {
    return API.requestPost({
      data: param,
      url: "user/check"
    });
  },
  switchXjd: param => {
    return API.requestPost({
      data: param,
      url: "user/switchXjd"
    });
  },
  switchShare: param => {
    return API.requestPost({
      data: param,
      url: "user/switchIsPromote"
    });
  },
  check: param => {
    return API.requestPost({ data: param, url: "user/auditMerchant" });
  },
  recharge: param => {
    return API.requestPost({ data: param, url: "user/manualRecharge" });
  },
  getMerchantRoles: param => {
    return API.requestPost({ data: param, url: "role/getMerchantRoles" });
  },
  delMerchants:param => {
    return API.requestPost({ data: param, url: "user/delUser" });
  },
};
